<template>
	<S2SForm :title="`Order ${selectedOrder.id ? selectedOrder.id : 'View'}`" :show-breadcrumbs="true">
		<template #no-padding>
			<v-flex xs12 class="hide-on-print">
				<v-card v-if="selectedOrder.id" dark :color="orderStatusColor(selectedOrder.status.id)" height="25px" class="d-flex">
					<v-card-text class="pa-0 text-center ma-auto">{{ selectedOrder.status.name }}</v-card-text>
				</v-card>
			</v-flex>
		</template>

		<S2SCard title="Order Details" dense color="white" flat toolbar-color="#d5d9dd" :container-props="{ class: 'py-0' }">
			<v-flex slot="toolbar-content" class="text-right hide-on-print" v-if="selectedOrder.id">
				<!-- More options menu -->
				<v-menu bottom origin="center center" transition="scale-transition" offset-y class="mr-2">
					<template v-slot:activator="{ on }">
						<v-btn color="secondary" medium v-on="on" class="mr-2">
							<v-icon>more_horiz</v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-list-item v-for="item in getMenuByStatus" :key="item.title" @click="item.action(selectedOrder)">
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>

				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn
							v-if="isOrderActionable(selectedOrder.status.id)"
							color="success"
							v-on="on"
							medium
							@click="onOrderMove(selectedOrder)"
							:loading="loading"
						>
							<v-icon>arrow_forward</v-icon>
						</v-btn>
					</template>
					<span>Move to {{ getNextOrderStatus(selectedOrder.status.id, selectedOrder.deliveryType).name }}</span>
				</v-tooltip>
			</v-flex>

			<v-container fluid grid-list-md py-1 v-if="selectedOrder.id">
				<v-layout row wrap>
					<v-flex pr-5 sm4 md4 class="print-details text-xs-left">
						<v-text-field hide-details pb-0 readonly :value="selectedOrder.id" label="Order ID" mt-0></v-text-field>
						<v-text-field
							ref="grn"
							hide-details
							pb-0
							v-model="selectedOrder.grn"
							placeholder="Please Provide GRN Nr"
							label="GRN"
							mt-0
							@input="onGrnChange($event)"
						></v-text-field>
						<v-btn color="primary" class="ml-0" v-if="isGRNDirty" @click="onGrnSave()">Save GRN</v-btn>
						<v-text-field
							hide-details
							readonly
							:value="Utils.formatText(selectedOrder.dateCreated, Utils.TextType.DATE_YYYY_MM_DD)"
							label="Date Created"
						></v-text-field>
						<v-text-field hide-details v-if="selectedOrder.user" readonly :value="selectedOrder.user.username" label="Placed By"></v-text-field>
						<v-text-field hide-details readonly :value="selectedOrder.paymentStatus" label="Payment Status"></v-text-field>
						<v-text-field hide-details class="print" readonly :value="selectedOrder.status.name" label="Order Status"></v-text-field>
					</v-flex>
					<v-flex pr-5 sm4 md4 class="print-details">
						<v-text-field hide-details readonly :value="selectedOrder.organisation.code" label="Customer Code"></v-text-field>
						<v-text-field hide-details readonly :value="selectedOrder.organisation.name" label="Customer Name"></v-text-field>
						<v-text-field hide-details v-if="!selectedOrder.user" readonly value="N/A" label="Customer"></v-text-field>
						<v-text-field
							hide-details
							v-if="selectedOrder.user"
							readonly
							:value="`${selectedOrder.user.profile.firstName} ${selectedOrder.user.profile.surname}`"
							label="Contact"
						></v-text-field>
						<v-text-field
							hide-details
							v-if="selectedOrder.user"
							readonly
							:value="selectedOrder.user.profile.cellphoneNumber"
							label="Number"
						></v-text-field>
						<v-text-field hide-details v-if="selectedOrder.user" readonly :value="selectedOrder.user.profile.email" label="Email"></v-text-field>
					</v-flex>
					<v-flex sm4 md4 mb-5 pb-3 class="print-details">
						<v-text-field hide-details readonly :value="selectedOrder.deliveryType" label="Fulfillment"></v-text-field>
						<br />
						<v-input
							:label="selectedOrder.deliveryType === 'DELIVERY' ? 'Delivery Address:' : 'Pickup Address:'"
							readonly
							class="address"
							hide-details
						>
							<div class="delivery-contents" v-if="selectedOrder.address">
								<div>{{ selectedOrder.address.complex }}</div>
								<br />
								{{ selectedOrder.address.streetNumber }} {{ selectedOrder.address.streetName }}
								<br />
								{{ selectedOrder.address.province }}
								<br />
								{{ selectedOrder.address.city }}
							</div>
							<div class="ml-2" v-else>N/A</div>
						</v-input>
						<br />
						<v-text-field
							hide-details
							readonly
							:value="selectedOrder.deliveryCost == null || selectedOrder.deliveryCost == 0 ? 'Free' : selectedOrder.deliveryCost"
							label="Delivery Fee"
						></v-text-field>
						<div v-if="selectedOrder.parcels && selectedOrder.status.id === 5">
							<v-text-field hide-details readonly :value="selectedOrder.parcels.count" label="Number of Parcels"></v-text-field>
							<v-chip v-for="size in selectedOrder.parcels.boxes" :key="size.key" class="mr-1" color="secondary" text-color="white" x-small pill>
								{{ size.name }}: {{ size.count }}
							</v-chip>
						</div>
					</v-flex>
					<v-flex v-if="selectedOrder.note">
						<br />
						<v-text-field hide-details readonly :value="selectedOrder.note" label="Order Notes" outlined></v-text-field>
					</v-flex>
				</v-layout>
			</v-container>
		</S2SCard>
		<v-spacer class="my-2"></v-spacer>
		<S2SCard title="Products" dense color="white" flat toolbar-color="#d5d9dd">
			<v-data-table :headers="productsHeader" :items="products" class="elevation-1">
				<template v-slot:item.options="props">
					{{ props.item.options ? props.item.options[0] : "-" }}
					<pre>{{ props.item.qunatity }}</pre>
				</template>
				<template v-slot:item.quantity="props">
					<v-edit-dialog :return-value.sync="props.item.quantity" large lazy @save="updateQty(props.item)">
						<div>{{ props.item.quantity }}</div>
						<div slot="input" class="mt-3 title">Update Quantity</div>
						<v-text-field slot="input" v-model.number="props.item.quantity" label="Edit" single-line autofocus mask="######"></v-text-field>
					</v-edit-dialog>
				</template>
				<template v-slot:item.detail.price="item">
					{{ Utils.formatText(item.item.detail.price, Utils.TextType.CURRENCY) }}
				</template>
				<template v-slot:item.price="item">
					{{ Utils.formatText(item.item.price, Utils.TextType.CURRENCY) }}
				</template>
				<template v-slot:item.discount="item">
					{{ discount(item) }}
				</template>
				<template v-slot:body.append>
					<tr v-if="$vuetify.breakpoint.smAndUp">
						<td>
							<strong class="left">Order Total:</strong>
						</td>
						<td v-for="index in productsHeader.length - 2" :key="index"></td>
						<td>
							<strong class="left">{{ Utils.formatText(selectedOrder.price, Utils.TextType.CURRENCY) }}</strong>
						</td>
					</tr>
					<tr v-else>
						<td class="v-data-table__mobile-row">
							<div class="v-data-table__mobile-row__wrapper">
								<div class="v-data-table__mobile-row__header">Order Total</div>
								<div class="v-data-table__mobile-row__cell">{{ Utils.formatText(selectedOrder.price, Utils.TextType.CURRENCY) }}</div>
							</div>
						</td>
					</tr>
				</template>
			</v-data-table>
		</S2SCard>
		<v-spacer class="my-2"></v-spacer>
		<S2SCard title="Transactions Logs" dense color="white" flat toolbar-color="#d5d9dd">
			<v-data-table :headers="transactionsHeader" :items="orderTransactions" hide-default-footer class="elevation-1">
				<template slot="items" slot-scope="props">
					<td class="text-xs-left" v-for="header in transactionsHeader" :key="header.id">{{ Utils.formatColumn(header, props.item) }}</td>
				</template>
				<template v-slot:item.amount="item">
					{{ Utils.formatText(item.item.amount, Utils.TextType.CURRENCY) }}
				</template>
				<template v-slot:item.timeStamp="item">
					{{ Utils.formatText(item.item.timeStamp, Utils.TextType.DATE_YYYY_MM_DD) }}
				</template>
			</v-data-table>
		</S2SCard>
		<v-spacer class="my-2"></v-spacer>
		<S2SCard title="Status Logs" dense color="white" flat toolbar-color="#d5d9dd" v-if="selectedOrder.id">
			<v-data-table :headers="statusLogsHeader" :items="selectedOrder.statusHistory" hide-default-footer class="elevation-1">
				<template slot="items" slot-scope="props">
					<td class="text-xs-left" v-for="header in statusLogsHeader" :key="header.id">{{ Utils.formatColumn(header, props.item) }}</td>
				</template>
			</v-data-table>
		</S2SCard>

		<SimpleDialog v-model="confirmPicup" title="Number of packages required" class="simple-dialog">
			<v-flex v-if="allowBoxSizes">
				<v-layout v-for="size in allowBoxSizes" :key="size.id">
					<v-text-field
						placeholder="# Packages"
						v-model="noOfPackages[size]"
						:label="boxSizes[size - 1].name"
						outlined
						hide-details
						class="mt-5"
					></v-text-field>
				</v-layout>
			</v-flex>
			<v-layout class="justify-end" slot="button-container">
				<v-btn text @click="confirmPicup = false" class="mr-2">Cancel</v-btn>
				<v-btn color="accent" :disabled="!Object.keys(noOfPackages).length" @click="setPackages">Save</v-btn>
			</v-layout>
		</SimpleDialog>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils/";
import SimpleDialog from "@/components/SimpleDialog.vue";

export default Vue.extend({
	name: "OrganisationSingleOrder",

	components: { SimpleDialog },

	props: {
		orderId: {
			type: String,
			default: ""
		}
	},

	computed: {
		loading: function() {
			return this.$store.state.organisations.activeOrders.loading;
		},
		selectedOrder: function() {
			return this.$store.state.organisations.activeOrders.selectedOrder;
		},
		orderTransactions: function() {
			return this.$store.state.organisations.activeOrders.orderTransactions;
		},
		boxSizes: function() {
			return this.$store.state.organisations.activeOrders.boxSizes;
		},
		products() {
			if (!this.selectedOrder.id) return [];
			return this.selectedOrder.items.filter(item => {
				return item.enabled;
			});
		},
		activeOrdersStatus: function() {
			return this.$store.getters["organisations/activeOrders/activeOrdersStatus"];
		},
		orderStatusColor: function() {
			return this.$store.getters["organisations/activeOrders/orderStatusColor"];
		},
		isOrderActionable: function() {
			return this.$store.getters["organisations/activeOrders/isOrderActionable"];
		},
		getAllowedBoxSizes: function() {
			return this.$store.getters["organisations/activeOrders/getAllowedBoxSizes"];
		},
		getMenuByStatus() {
			// TODO: we need to look at this logic a bit
			if (!this.selectedOrder || !this.selectedOrder.id) [];
			let previousStatus = null;

			switch (this.selectedOrder.status.id) {
				case 3:
					previousStatus = this.getOrderStatusById(2);
					break;
				case 5:
					previousStatus = this.getOrderStatusById(3);
					break;
				case 6:
					previousStatus = this.getOrderStatusById(5);
					break;
				default:
					// No previous status available
					return [
						{ title: "Print Order", action: this.printOrder },
						{ title: "Decline Order", action: this.onStatusDecline }
					];
			}
			if (this.selectedOrder.status.id === 5) {
				return [
					{ title: "Generate Waybill", action: this.generateWaybill },
					{ title: "Print Order", action: this.printOrder },
					{ title: "Decline Order", action: this.onStatusDecline },
					{ title: `Move to ${previousStatus.name}`, action: this.onOrderMoveBack }
				];
			} else {
				return [
					{ title: "Print Order", action: this.printOrder },
					{ title: "Decline Order", action: this.onStatusDecline },
					{ title: `Move to ${previousStatus.name}`, action: this.onOrderMoveBack }
				];
			}
		}
	},

	watch: {
		getAllowedBoxSizes: {
			deep: true,
			immediate: true,
			handler(newVal) {
				this.allowBoxSizes = newVal;
			}
		}
	},

	data: function() {
		return {
			Utils: Utils,

			productsHeader: [
				{ text: "SKU", value: "detail.sku" },
				{ text: "Product Name", value: "detail.name" },
				{ text: "Product Options", value: "options" },
				{ text: "Qty (Click to update quantity)", value: "quantity" },
				{ text: "Unit Price", value: "detail.price" },
				{ text: "Line Total", value: "price" },
				{ text: "Discount", value: "discount" }
			],

			statusLogsHeader: [
				{ text: "ID#", value: "id" },
				{ text: "Date", value: "statusTimestamp", type: "date" },
				{ text: "Status", value: "orderStatus.name" },
				{ text: "Description", value: "orderStatus.description" },
				{ text: "Modified By", value: "actorUser.username" }
			],

			transactionsHeader: [
				{ text: "Date", value: "timeStamp" },
				{ text: "Transaction ID", value: "id" },
				{ text: "Status", value: "statusName" },
				{ text: "Description", value: "typeName" },
				{ text: "Account", value: "accountName" },
				{ text: "Amount", value: "amount", type: "currency" }
			],

			orderDetailsData: {},

			isGRNDirty: false,

			confirmPicup: false,
			allowBoxSizes: [],
			noOfPackages: {},
			picupOrder: {},
			parcelParams: {
				message: "",
				parcels: []
			}
		};
	},

	mounted: function() {
		this.$store.dispatch("organisations/activeOrders/fetchBoxSizes");
		this.$store.dispatch("organisations/activeOrders/fetchAllowedBoxSizes");
		this.$store.dispatch("organisations/activeOrders/fetchOrderStatusses");
		this.$store.dispatch("organisations/activeOrders/fetchOrder", +this.$props.orderId);
		this.$store.dispatch("organisations/activeOrders/fetchTransaction", +this.$props.orderId);
	},

	methods: {
		onGrnChange(val) {
			if (this.selectedOrder.grn) this.isGRNDirty = true;
			else this.isGRNDirty = false;
		},
		onGrnSave() {
			this.$store.dispatch("organisations/activeOrders/addOrderGRN", { orderId: this.selectedOrder.id, grn: this.selectedOrder.grn });
			this.isGRNDirty = false;
		},
		updateQty(orderItemBody) {
			this.$store.dispatch("organisations/activeOrders/updateOrderItem", { orderItem: orderItemBody, orderId: this.selectedOrder.id });
		},
		discount(item) {
			if (item.item.markup == null) {
				return "-";
			} else {
				let discount = item.item.markup * item.item.quantity < 0 ? item.item.markup * item.item.quantity * -1 : item.item.markup * item.item.quantity;
				return Utils.formatText(discount.toString(), Utils.TextType.CURRENCY);
			}
		},
		onStatusDecline(order) {
			this.$store.dispatch("organisations/activeOrders/declineOrder", {
				order: order,
				statusIds: [this.selectedOrder.status.id],
				paymentStatus: [],
				orgsearch: ""
			});
		},
		async printOrder() {
			const modal = this.$el;
			const cloned = modal.cloneNode(true);
			let section = document.getElementById("print");

			if (!section) {
				section = document.createElement("div");
				section.id = "print";
				document.body.appendChild(section);
			}

			section.innerHTML = "";
			section.appendChild(cloned);

			window.print();
		},
		getOrderStatusById(statusId) {
			for (let status in this.activeOrdersStatus) {
				if (this.activeOrdersStatus[status].id === statusId) return this.activeOrdersStatus[status];
			}

			// This will most likely not happen
			return this.activeOrdersStatus[0];
		},
		getNextOrderStatus(statusId, deliveryType) {
			switch (statusId) {
				case 2:
					return this.getOrderStatusById(3);
				case 3:
					return this.getOrderStatusById(5);
				case 5:
					if (deliveryType === "DELIVERY") return this.getOrderStatusById(6);
					else return { name: "Completed" };
				default:
					return { name: "Completed" };
			}
		},
		onOrderMove(order) {
			if (order.status.id === 3 && order.deliveryType === "DELIVERY") {
				// from accepted => ready
				this.confirmPicup = true;
				this.picupOrder = order;
			} else {
				this.$store.dispatch("organisations/activeOrders/changeOrderStatus", { order: order });
			}
		},
		setPackages() {
			let nonEmptyFields = Object.keys(this.noOfPackages).filter(sizeId => {
				return this.noOfPackages[sizeId] != "";
			});

			if (nonEmptyFields.length !== 0) {
				// parcel data
				nonEmptyFields
					.filter(sizeId => {
						return this.noOfPackages[sizeId] != "";
					})
					.forEach(sizeId => {
						let parcelData = {
							boxSizeId: sizeId,
							count: this.noOfPackages[sizeId]
						};

						this.parcelParams.parcels.push(parcelData);
					});

				this.$store.dispatch("organisations/activeOrders/changeOrderStatus", {
					order: this.picupOrder,
					params: this.parcelParams
				});
			}
			this.confirmPicup = false;
		},
		onOrderMoveBack(order) {
			this.$store.dispatch("organisations/activeOrders/moveOrderBackwards", { order: order });
		},
		generateWaybill(order) {
			this.$router.push({ name: "view-order-waybill", params: { orderId: this.selectedOrder.id.toString(), order: order } });
		}
	}
});
</script>

<style scoped>
.delivery-content {
	position: absolute;
	margin-top: 40px;
}

.v-text-field__details {
	display: none;
}

/* // Remove the default line at bottom of text field */
.v-text-field >>> .v-input__control > .v-input__slot:after,
.v-text-field >>> .v-input__control > .v-input__slot:before {
	border-style: none !important;
}
</style>

<style>
@media screen {
	#print {
		display: none;
	}

	.print {
		display: none;
	}

	.address {
		position: relative;
	}
	.address .v-label {
		position: absolute !important;
		top: 0;
		font-size: 13px;
	}
}

@media print {
	body * {
		visibility: hidden;
	}

	.hide-on-print {
		display: none !important;
	}

	main {
		padding: 0 !important;
	}

	#print,
	#print * {
		visibility: visible;
	}

	#print {
		position: absolute;
		top: 0;
		width: 100%;
	}

	.address {
		position: relative;
	}
	.address .v-label {
		position: absolute !important;
		top: 0;
		font-size: 13px;
	}
}
</style>
